const gallery = [
    {key:0 ,src: 'https://live.staticflickr.com/65535/52935231898_7f7b5dc8e5_o.jpg',caption:'concerts'},
    {key:1, src: 'https://live.staticflickr.com/65535/52934187297_39d9d0c0f5_o.jpg',caption:'workshops'},
    {key:2, src: 'https://live.staticflickr.com/65535/52935231903_01c3c040df_o.jpg',caption:'lectures'},
    {key:3, src: 'https://live.staticflickr.com/65535/52935176750_207fb94e17_o.jpg',caption:'sonicart'},
    {key:4, src: 'https://live.staticflickr.com/65535/52935182950_9e8b9064a0_o.jpg',caption:'concerts'},
    {key:6, src: 'https://live.staticflickr.com/65535/52940372043_8e4816fbcc_o.jpg',caption:'workshops'},
    {key:5, src: 'https://live.staticflickr.com/65535/52939320312_695a449d81_o.jpg',caption:'sonicart'},
    {key:7, src: 'https://live.staticflickr.com/65535/52940303725_721decf07d_o.jpg',caption:'concerts'},
    {key:8, src: 'https://live.staticflickr.com/65535/52940060749_fae814d407_o.jpg',caption:'workshops'},
];

export default gallery;








